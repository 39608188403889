import React from 'react'
import { MainLayout } from 'components/layouts'
import css from './style.module.scss'
import cn from 'classnames'
import {
  IconVK,
  IconFacebook
} from 'components/icons'
import Pic1 from '../../images/img1.jpg'
import Pic2 from '../../images/img2.jpg'
import Pic3 from '../../images/img3.jpg'
import ShabbathSetBanner from '../../components/common/ShabbathSetBanner/ShabbathSetBanner'
import SEO from 'components/seo'

const WhatIsShabbathPage = () => {
  return (
    <MainLayout>
      <SEO
        title='Что такое Шабат?'
      />
      <div className={cn(css.whatIsShabbathPage, 'container')}>

        <div className={css.article}>

          <div className={css.head}>

            <a href="/"
               className={cn("linkBack caption primary-color", css.back)}>
              Вернуться на главную
            </a>

            <h1>Что такое Шабат?</h1>
            <p>В память о сотворении мира Тора заповедала нам почитать седьмой день недели —
              Шабат, отдыхать в этот день и не совершать работы.
              Узнайте больше об истории и значении Шабата из нашей статьи.</p>
          </div>

          <div className={css.articlePic}>
            <img src={Pic1} alt="article pic" />
          </div>

          <div className={css.body}>

            <h4>Шабат — зачем?</h4>
            <p>«И были завершены небеса, и земля, и всё воинство их».
              Этими словами завершает Тора самую таинственную историю – сотворение мира.</p>
            <p>Сотворение мира произошло примерно шесть тысяч лет назад.
              Тогда Вс-вышний сотворил небеса и землю, море и сушу, растения, солнце, луну и звёзды, животный мир,
              и разумеется, первых людей – Адама и Хаву.</p>
            <p>Всё, что составляет нашу удивительную вселенную, было сотворено за шесть дней «работы».</p>

            <blockquote>В эти дни Творец показал часть Своей великой силы и произвёл удивительную вещь –
              сотворил из ничего тот мир, в котором мы живём</blockquote>

            <p>После окончания сотворения мира в шестой день Творец как бы отдыхал от работы творения в течение целых суток,
              покоился и не совершал работу. Он благословил седьмой день и придал ему особую святость.</p>
            <p>Творец предназначил седьмой день для покоя, святости и духовного подъёма.
              В этот день Его любимые создания могут узнать Его, их Творца,
              очиститься от телесности и материальности и хотя бы один день в неделю прожить духовной жизнью,
              более чистой и святой. </p>
            <p>В память о сотворении мира Тора заповедала нам почитать седьмой день недели,
              отдыхать в этот день и не совершать работы.</p>
          </div>

          <div className={css.articlePic}>
            <img src={Pic2} alt="article pic" />
          </div>

          <div className={css.body}>

            <h4>Отдых</h4>
            <p>Шабат – это остров спокойствия в круговороте хлопот, беспокойства, борьбы и невзгод,
              который характеризует нашу повседневную жизнь в течение остальных шести дней недели.</p>
            <p>Каждую неделю приблизительно на 25 часов мир буквально замирает: бизнес закрыт,
              автомобиль стоит в гараже, телефон не звонит, радио, телевизор и компьютер отключены,
              заботы материальной жизни скрываются за пеленой забвения. Прекращая свою созидательную причастность к физическому миру,
              мы фокусируемся на нашем внутреннем мире - на семье, друзьях, на нашей внутренней сущности, нашей душе.</p>

            <h4>Осознание</h4>
            <p>В Шабат мы вспоминаем, что не можем делать с миром все, что нам заблагорассудится,
              поскольку он не принадлежит нам, а является творением Б-га.</p>
            <p>В Шаббат мы также вспоминаем, что Б-г вывел нас из Египта и повелел, чтобы мы никогда не были рабами земных хозяев:
              наша работа, финансы и материальные занятия – это лишь инструменты, средства достижения нашей Б-жественной цели,
              но не властители нашей жизни.</p>

            <h4>Самоидентификация</h4>
            <p>Шабат – это невеста Израиля, неразлучная с еврейским народом.
              Это - один из самых лучших путей проявить наше еврейство и передать его нашим детям.
              Мы остались верными Шаббату в любых странах, культурах и обстоятельствах в течении всей нашей четырехтысячелетней истории –
              и в славные дни Соломона, и в черные ночи Инквизиции. И эта преданность в свою очередь явилась средством сохранения нашей еврейской идентификации.
              Или словами известного еврейского автора: "Даже больше чем евреи хранили Субботу - Суббота хранила евреев."</p>

            <blockquote>"Даже больше чем евреи хранили Субботу — Суббота хранила евреев."</blockquote>

            <h4>Наслаждение</h4>
            <p>Шабат – это вкусная трапеза, роскошный стол, мерцание свечей, сладкое пение, наслаждающий сон.
              В течение недели удовольствие от жизненных радостей сопряжено с определенной проблемой:
              мы - физические существа в физическом мире, и должны быть всегда настороже, чтобы удовольствия не привели нас к деградации.
              Но в Шаббат и душа и тело вознесены на более высокую, более духовную ступень,
              и наслаждаться Субботой, вкушая пищу, напитки и комфорт – это мицва, Б-жественное деяние.</p>
          </div>

          <div className={css.articlePic}>
            <img src={Pic3} alt="article pic" />
          </div>

          <div className={css.body}>

            <h4>Духовность</h4>
            <p>Шабат – это душа недели. Идея, оживляющая ее и задающая ей направление.
              Как учат мудрецы Каббалы, в Шабат все свершенной в течении всей предыдущей недели достигает своего полного осуществления и возвышения,
              и Суббота же дает благословение всем свершениям наступающей недели.</p>
            <p>Соблюдение Шабата обеспечивает благословение Всевышнего на успех в течение всей недели,
              и наделяет целесообразностью и смыслом наше повседневное существование.</p>

            <h4>Вкус Грядущего Мира</h4>
            <p>"В то время не будет ни голода ни войн, ни зависти ни соперничества. Все будет в изобилии, и самые изысканные яства будут доступны, как пыль.
              Все устремление мира будет лишь к тому, чтобы познать Б-га". Так пророки и мудрецы Израиля описывают Эру Избавления -
              "седьмое тысячелетие", которое будет конечной реализацией и исполнением шеститысячелетней истории человечества и усилий превратить этот мир в "жилище Б-га".</p>
            <p>Каждую неделю Шабат дает нам почувствовать вкус этого будущего мира. Но подобно тому, как вкус любого деликатеса,
              сколько его ни описывай, нельзя понять, самостоятельно его не испытав, - так же и с сутью Шабата.</p>

            <blockquote>В конечном счете, самый правильный ответ на вопрос, что такое Шабат, — это “Попробуйте!"</blockquote>

            <h4>День покоя</h4>
            <p>Шабат останавливает повседневную рутину, заботы о заработке и будничные дела.
              Он поднимает нас высоко, на духовную высоту.</p>
            <p>Всякий, кто соблюдает шабат, знает чудесную атмосферу, окружающую нас уже в пятницу.
              В этот день мы готовимся к переходу из повседневного мира в совсем другой мир покоя, безмятежности и святости.
              Мы закрываем наши предприятия, заканчиваем работать раньше, оставляем в стороне все заботы и проблемы, думы о том,
              как заработать лишнюю копейку, и все размышления о том, как улучшить материальную составляющую нашей жизни.</p>

            <blockquote>Соблюдение субботы — это один из основных способов показать своё еврейство и передать его детям и внукам.</blockquote>

            <p>Один раз в неделю в течение 25 часов внешний мир замирает для нас: наше предприятие закрыто, машина стоит на стоянке,
              телефон, радио и компьютер выключены, смартфон отдыхает, а вместе с ним и весь виртуальный мир, не отпускающий нас целые сутки.</p>
            <p>Заботы жизни временно отступают. Мы прекращаем всякое активное вмешательство в материальный мир,
              и наш взгляд устремляется внутрь – в духовный мир, в мир чувств и мыслей, во внутренний мир.</p>
            <p>Мы можем посвятить время семье, друзьям (ведь за множеством виртуальных связей уже почти не осталось времени на реальные разговоры),
              и главное – себе, своей душе.</p>

            <a href="https://ru.chabad.org/"
               target="_blank"
               rel="noreferrer"
            >
              Узнать еще о Шабате
            </a>

            <div className={css.social}>
              <span>Поделиться:</span>
              <a href="https://vk.com/share.php?url=https://shabbatshalom.ru/what-is-shabbath&amp;title=Что такое Шабат?" 
                 target="_blank"
                 rel="noreferrer"
              >
                <IconVK />
              </a>
              {/* <a href="https://www.facebook.com/sharer.php?u=https://shabbatshalom.ru/what-is-shabbath&amp;title=Что такое Шабат?" 
                 target="_blank"
                 rel="noreferrer"
              >
                <IconFacebook />
              </a> */}
            </div>

          </div>

        </div>

      </div>

      <ShabbathSetBanner />
    </MainLayout>
  )
}

export default WhatIsShabbathPage